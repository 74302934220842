
import {
  PAGE_TITLE,
  PAGE_SUBTITLE,
  PAGE_ADD_GROUP_BUTTONS,
  PAGE_ACTIVE_GROUP_BUTTON,
  PAGE_CLEAR_PAGE,
  PAGE_ADD_ICON_BUTTONS,
  PAGE_REMOVE_ICON_BUTTON,
  PAGE_CLEAR_ICON_BUTTONS,
  PAGE_ADD_ICON_BUTTON_EVENT,
  PAGE_CLEAR_ICON_BUTTON_EVENTS,
  PAGE_REMOVE_ICON_BUTTON_EVENT,
  PAGE_ADD_CUSTOM,
  PAGE_CLEAR_CUSTOM
} from "./PageConstants";
import { initialPageContext } from './PageContext';

const page = (state = initialPageContext, action) => {

  switch (action.type) {
    case PAGE_TITLE:
      return {
        ...state,
        title: action.title
      }

    case PAGE_SUBTITLE:
      return {
        ...state,
        subtitle: action.subtitle
      }

    case PAGE_ADD_GROUP_BUTTONS:
      return {
        ...state,
        buttons: action.buttons
      }

    case PAGE_ACTIVE_GROUP_BUTTON:
      return {
        ...state,
        activeButton: action.activeButton
      }

    case PAGE_ADD_ICON_BUTTONS:
      return {
        ...state,
        iconButtons: [
          ...state.iconButtons,
          ...action.iconButtons
        ]
      }

    case PAGE_REMOVE_ICON_BUTTON:
      return {
        ...state,
        iconButtons: state.iconButtons.filter(f => f.key !== action.key)
      }

    case PAGE_CLEAR_PAGE:
      return {
        ...initialPageContext
      }

    case PAGE_CLEAR_ICON_BUTTONS:
      return {
        ...state,
        iconButtons: []
      }

    case PAGE_ADD_ICON_BUTTON_EVENT:
      var { key, handler } = action;
      var exist = state.handlers.find(f => f.key === key);

      var element = document.querySelector(`#${key}`);
      if (element != null) element.addEventListener("click", handler);

      return {
        ...state,
        handlers: [
          ...state.handlers,
          {
            key,
            handler,
            element
          }
        ]
      }

    case PAGE_REMOVE_ICON_BUTTON_EVENT:
      var { key } = action;
      var handler = state.handlers.filter(f => f.key === key);
      var { element, handler } = handler;
      if (element != null) element.removeEventListener("click", handler);

      return {
        ...state,
        handlers: state.handlers.filter(f => f.key != key)
      }


    case PAGE_CLEAR_ICON_BUTTON_EVENTS:
      state.handlers.map(handler => {
        const { element } = handler;
        if (element != null) element.removeEventListener("click", handler.handler);
      })
      return {
        ...state,
        handlers: []
      }

    case PAGE_ADD_CUSTOM:
      return {
        ...state,
        custom: action.custom
      }

    case PAGE_CLEAR_CUSTOM:
      return {
        ...state,
        custom: []
      }



    default:
      return state;
  }
}

export default page;