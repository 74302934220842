import React from 'react';
import { usePageContext } from 'components/Page/redux/PageContext';

export default function Custom() {
  const { state: { custom } } = usePageContext();
  return (
    <>
      {custom}        
    </>
  )
}

