import React, {useContext} from 'react';
import PageContext from 'components/Page/redux/PageContext';

export default function HeaderTitle() {
  const {state} = useContext(PageContext);
  const {title} = state;
  return (
    <div>
      <div className="header-menu d-inline-block mt-3 ml-2 mr-3">
        <h3 className="title m-0 mb-1">{title}</h3>
      </div>
    </div>
  );
}
