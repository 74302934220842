import React, { useContext, useReducer } from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PageContext, {usePageContext} from 'components/Page/redux/PageContext';
import PageReducer from 'components/Page/redux/PageReducer';

import configureStore, {history} from './store';
//import './firebase/firebase';
import App from './containers/App';

// import {LicenseManager} from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");

export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [state, dispatch] = useReducer(PageReducer, initialPageContext);
  return (
    
    <PageContext.Provider value={{state, dispatch}}>
      <App {...props} />
    </PageContext.Provider>    
    )
}

export default function MainApp() {
  return (
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <Switch>
            <Route path="/" component={Apps}/>
        </Switch>
    </ConnectedRouter>
  </Provider>
  );
}