import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open')
          }
        }
        this.classList.toggle('open');
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
      
      <li className="nav-header">
        <IntlMessages id="sidebar.modules" />
      </li>

      <li className="menu no-arrow">
      <NavLink to="/app/checklist">
        <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
        <span className="nav-text font-weight-bold">
          Check List
        </span>
      </NavLink>
    </li>

      <li className="menu no-arrow">
        <NavLink to="/app/quotes">
          <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.quotes" />
          </span>
        </NavLink>
      </li>


      <li className="menu no-arrow">
        <NavLink to="/app/finalaccount">
          <i className="zmdi zmdi-railway zmdi-hc-fw" />
          <span className="nav-text"><IntlMessages id="sidebar.modules.finalAccount" /></span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/deliveryschedulephasing">
          <i className="zmdi zmdi-receipt zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.deliverySchedulePhasing" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/stockprediction">
          <i className="zmdi zmdi-roller zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.stockPrediction" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/reports">
          <i className="zmdi zmdi-settings-square zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.reports" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/revitupload">
          <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.revitUpload" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/productlist">
          <i className="zmdi zmdi-sun zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.productList" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/assemblieslist">
          <i className="zmdi zmdi-ticket-star zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.assembliesList" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/chinatracker">
          <i className="zmdi zmdi-toys zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.chinaTracker" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/wms">
          <i className="zmdi zmdi-toll zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.wms" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/map">
          <i className="zmdi zmdi-truck zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.map" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/erp">
          <i className="zmdi zmdi-wallpaper zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.erp" />
          </span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/client">
          <i className="zmdi zmdi-wallpaper zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.client" />
          </span>
        </NavLink>
      </li>


      {/*
      <li className="menu">
        <a role="button" href="javascript:void(0)">
          <i className="zmdi zmdi-library zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.reports" />
          </span>
        </a>

        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon" to="/app/reports/projectreport">
              <IntlMessages id="sidebar.reports.projectReports" />
            </NavLink>
          </li>
        </ul>
      </li>
      */}
    </ul>
  </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
