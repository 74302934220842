// Page const
export const PAGE_TITLE = 'PAGE_TITLE';
export const PAGE_SUBTITLE = 'PAGE_SUBTITLE';
export const PAGE_ADD_GROUP_BUTTONS = 'PAGE_ADD_GROUP_BUTTONS';
export const PAGE_ACTIVE_GROUP_BUTTON = 'PAGE_ACTIVE_GROUP_BUTTON';
export const PAGE_CLEAR_PAGE = 'PAGE_CLEAR_PAGE';
export const PAGE_ADD_ICON_BUTTONS = 'PAGE_ADD_ICON_BUTTONS';
export const PAGE_CLEAR_ICON_BUTTONS = 'PAGE_CLEAR_ICON_BUTTONS';
export const PAGE_ADD_ICON_BUTTON_EVENT = 'PAGE_ADD_ICON_BUTTON_EVENT';
export const PAGE_CLEAR_ICON_BUTTON_EVENTS = 'PAGE_CLEAR_ICON_BUTTON_EVENTS';
export const PAGE_REMOVE_ICON_BUTTON = 'PAGE_REMOVE_ICON_BUTTON';
export const PAGE_REMOVE_ICON_BUTTON_EVENT = 'PAGE_REMOVE_ICON_BUTTON_EVENT';
export const PAGE_ADD_CUSTOM = 'PAGE_ADD_CUSTOM';
export const PAGE_CLEAR_CUSTOM = 'PAGE_CLEAR_CUSTOM';
export const CLEAR_ICON_BUTTONS = 'CLEAR_ICON_BUTTONS';

